body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: rgb(245, 245, 245);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page {
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-grow: 10;
}

.content {
  /* background-color: #bdded384; */
  margin-top: -10px;
  margin-bottom: 16px;
}

.tom {
  height: 100%;
}

html, body, #root, .page {
  height: 100%;
}

.card {
  border-radius: 7px;
  margin-bottom: 10px;
  padding: 6px;
  border-width: 2px;
}

.center-icon {
  text-align: center;
  font-size: 30px;
}

.list-notes {
  display: flex;
}

.bold {
  font-weight: 600;
}

.medium {
  font-weight: 600;
}

.nav {
  font-weight: 600;
  line-height: 34px;
}