.dropdown {
    display: flex;
    align-items: right;
    justify-content: right;
    flex: 1;
    height: 100%;
    padding-bottom: 20;
    font-size: 1.5 rem;
}

.dropdown-button {
    color: rgb(68, 176, 86);
    background-color: transparent;
    border: transparent;
    font-size: 1.15rem;
}

.header {
    margin-bottom: 0;
}

.button {
    font-weight: 600;
}